import * as React from 'react';

function CourierIcon(props) {
  const fill = props.style && props.style.fill ? props.style.fill : 'var(--primaryDarkSvg)';
  return (
    <svg width={24} height={24} viewBox='0 0 24 24' {...props}>
      <path
        d='M3.17382 3.28569C2.95873 3.51783 2.77948 3.89283 2.77948 4.14283C2.77948 4.41069 3.80118 5.92854 5.57571 8.3214C7.09929 10.3928 8.58703 12.3928 8.87382 12.8035L9.39363 13.5357L8.28231 14.625L7.17099 15.7143L5.39646 14.3928C3.51439 12.9821 2.90495 12.7857 2.36722 13.375C2.09835 13.6607 2.0625 14.2143 2.0625 17.3214C2.0625 20.9285 2.0625 20.9285 2.51061 21.375C2.9408 21.8035 3.0125 21.8214 5.23514 21.7678L7.52948 21.7143V21.2678V20.8214L5.37854 20.7321L3.22759 20.6428L3.17382 17.375C3.13797 14.7678 3.17382 14.125 3.35307 14.2321C3.47854 14.3035 4.37476 14.9643 5.32476 15.6785C6.27476 16.3928 7.17099 16.9821 7.31439 16.9821C7.60118 16.9821 10.6663 13.9107 10.6663 13.6071C10.6663 13.5 9.14269 11.3393 7.26061 8.80354C5.39646 6.28569 3.85495 4.16069 3.85495 4.0714C3.85495 3.99997 5.0559 3.9464 6.54363 3.9464H9.21439L9.26816 5.9464L9.32193 7.96426H11.5625H13.8031L13.8568 5.9464L13.9106 3.9464L16.904 3.98211L19.8974 4.03569V12.3393V20.6428L18.4634 20.7321C17.0833 20.8214 17.0295 20.8393 17.0295 21.2678C17.0295 21.7143 17.0653 21.7143 18.6068 21.7678C20.0587 21.8214 20.2021 21.7857 20.6144 21.375L21.0625 20.9285V12.3393V3.74997L20.6144 3.3214L20.1842 2.87497H11.8672H3.55024L3.17382 3.28569Z'
        fill={fill}
      />
      <path
        d='M9.89528 16.4107L8.13867 18.1607L8.9632 20.125C10.1104 22.8214 10.2 22.875 12.6915 22.875C13.8028 22.875 14.7887 22.8036 14.8962 22.7321C15.434 22.375 15.4698 21.8929 15.1292 19.8571C14.9321 18.75 14.8245 17.7857 14.8783 17.7321C14.9321 17.6786 15.5774 17.7679 16.3123 17.9464C17.8179 18.3036 18.1943 18.1964 18.1943 17.4107C18.1943 17.0536 17.9255 16.8393 16.4377 16.1071C14.5557 15.1607 13.0679 14.6607 12.1896 14.6607C11.7415 14.6607 11.3472 14.9464 9.89528 16.4107Z'
        fill={fill}
      />
    </svg>
  );
}

export default CourierIcon;
