import React from 'react';
import * as yup from 'yup';
import ThreePLSvg from '../images/svg/ThreePLSvg';
import CustomsSvg from '../images/svg/CustomsSvg';
import FreightForwarderSvg from '../images/svg/FreightForwarderSvg';
import ImportExportSvg from '../images/svg/ImportExportSvg';
import TradeSvg from '../images/svg/TradeSvg';
import ManufacturingSvg from '../images/svg/ManufacturingSvg';
import TransportSvg from '../images/svg/TransportSvg';
import WarehouseCompTypeSvg from '../images/svg/WarehouseCompTypeSvg';
import { agreementType } from '../../components/Authenticated/Agreement/Elements/AgreementContainer/AgreementContainer';
import { nestedCompanyRoutes } from '../../constants/paths';
import OtherSvg from '../images/svg/OtherSvg';
import ServiceProviderSvg from '../images/svg/ServiceProviderSvg';
import BeveragesSvg from '../images/svg/cargo_types_icons/BeveragesSvg';
import ChemicalsSvg from '../images/svg/cargo_types_icons/ChemicalsSvg';
import FurnitureSvg from '../images/svg/cargo_types_icons/FurnitureSvg';
import IndustrialSvg from '../images/svg/cargo_types_icons/IndustrialSvg';
import ApparelsSvg from '../images/svg/cargo_types_icons/ApparelsSvg';
import BuildingMaterialsSvg from '../images/svg/cargo_types_icons/BuildingMaterialsSvg';
import VehiclesEnginesSvg from '../images/svg/cargo_types_icons/VehiclesEnginesSvg';
import ApplianceElectronicsSvg from '../images/svg/cargo_types_icons/ApplianceAndELectronicsSvg';
import GlassPaperSvg from '../images/svg/cargo_types_icons/GlassPaperSvg';
import HazardousSvg from '../images/svg/cargo_types_icons/HazardousSvg';
import OtherTypesSvg from '../images/svg/cargo_types_icons/OtherTypesSvg';
import RoadIcon from '../../assets/images/svg/RoadIcon';
import SeaIcon from '../../assets/images/svg/SeaIcon';
import AirIcon from '../../assets/images/svg/AirIcon';
import RailIcon from '../../assets/images/svg/RailIcon';
import CourierIcon from '../../assets/images/svg/CourierIcon';
import PdfIconSvg from '../../assets/images/svg/PdfIconSvg';
import JpgIconSvg from '../../assets/images/svg/JpgIconSvg';
import PngIconSvg from '../../assets/images/svg/PngIconSvg';
import XlsIconSvg from '../../assets/images/svg/XlsIconSvg';
import DocIconSvg from '../../assets/images/svg/DocIconSvg';
import OdtIconSvg from '../../assets/images/svg/OdtIconSvg';
import OdsIconSvg from '../../assets/images/svg/OdsIconSvg';
import localization from '../localization';

/**
 * Returns the localized subscription type based on the subtype ID.
 *
 * @param {number|null} subtypeId - The subscription subtype identifier.
 * @param {object} localization - The localization object containing the text for each subscription type.
 * @returns {string} The localized text for the given subscription subtype.
 *
 * @example
 * // Returns localization.initialSub
 * parseCompanySubscriptionEnums(1, localization);
 *
 * @example
 * // Returns localization.standardType
 * parseCompanySubscriptionEnums(2, localization);
 *
 * @example
 * // Returns localization.promoBusinessType
 * parseCompanySubscriptionEnums(9, localization);
 *
 * The function uses an object lookup to return the appropriate localized text
 * based on the input subscription subtype ID. If the subtype ID is not recognized,
 * it returns the default localization for 'payAsYouGo'.
 */
export function parseCompanySubscriptionEnums(subtypeId, localization) {
  const localizationMap = {
    1: localization.initialSub,
    2: localization.standardType,
    3: localization.standardType,
    4: localization.businessType,
    5: localization.businessType,
    6: localization.premiumType,
    7: localization.premiumType,
    8: localization.promoBusinessType,
    9: localization.promoBusinessType,
    10: localization.promoBusinessType,
    11: localization.promoBusinessType,
    null: localization.payAsYouGo
  };

  return localizationMap[subtypeId] || localization.payAsYouGo;
}

export function parseCompanySubTypeFrequency(subtypeId, localization) {
  switch (subtypeId) {
    case 2:
    case 4:
    case 6:
      return localization.monthly.toLowerCase();
    case 3:
    case 5:
    case 7:
      return localization.annual.toLowerCase();
    case 8:
      return `3 ${localization.months.toLowerCase()}`;
    case 9:
      return `6 ${localization.months.toLowerCase()}`;
    case 10:
      return `9 ${localization.months.toLowerCase()}`;
    case 11:
      return localization.annual.toLowerCase();
    default:
      return null;
  }
}

yup.addMethod(yup.number, 'validateStringAsNumber', function () {
  return this.transform((value) => (isNaN(value) ? undefined : value));
});

/**
 * A function that checks if the api response has the desired error codes
 * @param {array} errCodes [11006, 11007]
 * @param {array} apiResponseErrors - The errors returned from the API
 * @returns {array} array of codes that api response has that match with desired error codes
 */
export function prepareApiErrorCodes(errCodes, apiResponseErrors, strings) {
  return (
    apiResponseErrors &&
    apiResponseErrors.filter &&
    apiResponseErrors
      .filter((e) => errCodes.includes(e.error_code))
      .map((e) => errCodes.includes(e.error_code) && strings && strings[e.error_code])
  );
}

/**
 *
 * @param {response object} enums
 * @param {object} strings
 */
export function parseDbEnums(enums, strings) {
  const enumArray = enums?.split(',') || [];
  const localizedValues = [];
  enumArray.map((value) => {
    return localizedValues.push(strings[value]);
  });
  return localizedValues;
}

/**
 *
 * @param {response object} enums
 * @param {object} strings
 */
export function parseDbEnumsForSelect(enums, strings) {
  const localizedValues = [];
  enums.map((val) => {
    return localizedValues.push({ value: val.id, label: strings[val.type] });
  });
  return localizedValues;
}
/**
 *
 * @param {response object} enums
 * @param {object} strings
 */
export function parseCmrValuesForSelect(enums, strings, prefix) {
  const localizedValues = [];
  let pre = '';
  if (prefix) {
    pre = strings[prefix];
  }
  enums.map((val) => {
    return localizedValues.push({
      value: String(val.id),
      label:
        val.id === 6
          ? `${strings['over'].toLowerCase()} €${val.value.replace('{{over}}', '')},00`
          : `${pre} €${val.value},00`
    });
  });
  if (prefix === 'minFilter') {
    localizedValues.pop();
  }
  return localizedValues;
}
export function parseCmrOptions(id, over) {
  if (id) {
    const values = ['1', '2', '3', '5', '1.0', `${over.toLowerCase()} €1.0`];
    return id !== 6 ? '€' + values[id - 1] + '00.000,00' : values[id - 1] + '00.000,00';
  } else {
    return null;
  }
}

export function capitalizeWord(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function removeUnderscoresAndCapitalize(string) {
  const removeUnderscores = string.replace(/_/g, ' ');
  const toLowerCase = removeUnderscores.toLowerCase();
  const capitalized = toLowerCase.charAt(0).toUpperCase() + toLowerCase.slice(1);
  return capitalized;
}

export function calculateRating(rating) {
  return rating * 20;
}

export function generateRandomId() {
  return Math.round(Math.random() * 1000000000000).toString();
}

export function sortPhoneCodes(phoneCodes) {
  return phoneCodes.sort(function (a, b) {
    if (a.value.length > b.value.length) {
      return 1;
    } else if (a.value.length < b.value.length) {
      return -1;
    } else {
      return a.value.localeCompare(b.value);
    }
  });
}

export function formatPrice(price) {
  const formatter = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR'
  });

  const formattedPrice = formatter.format(price);

  const currencySymbol = formattedPrice.replace(/[0-9,.]/g, '').trim();

  const pricePart = formattedPrice.replace(currencySymbol, '').trim();

  return `${currencySymbol} ${pricePart}`;
}

export function formatNumber(number, unit = '') {
  if (number === undefined) {
    return '...';
  }
  const formatter = new Intl.NumberFormat('de-DE');
  return `${formatter.format(number)} ${unit}`;
}

export function formatWeight(number) {
  if (number === undefined) {
    return '...';
  }
  const formatter = new Intl.NumberFormat('de-DE');
  if (number < 1) {
    return `${formatter.format((number * 1000).toFixed(2))} kg`;
  } else {
    return `${formatter.format(number.toFixed(2))} t`;
  }
}

export function filterCompanyCargoToOptions(customCargo, companyCargo, localization) {
  const cargoOptions = [
    customCargo,
    {
      label: localization.predefinedCargo,
      options: []
    }
  ];

  companyCargo?.company_cargo.map(({ id, ...data }) => {
    return cargoOptions[1].options.push({
      value: id,
      label: data.name,
      data: {
        ...data
      }
    });
  });
  return cargoOptions;
}

export function filterCountriesToOptions(countries, label, allowEmptyOption = false, isLowerCase = false) {
  const arr = allowEmptyOption ? [{ value: '', label: label }] : [];
  countries?.map((e) => {
    return arr.push({
      value: isLowerCase ? e.country_code.toLowerCase() : e.country_code,
      label: e.country_name,
      area_code: e.area_code
    });
  });
  return arr;
}

export function filterCitiesToOptions(cities, localization, allowEmptyOption = false) {
  const arr = allowEmptyOption ? [{ value: '', label: localization.noCitySelected }] : [];

  cities?.map((e) => {
    return arr.push({ value: e.id, label: e.city_name, postal_code: e.postal_code });
  });
  return arr;
}

export function filterPostalCodeToOptions(cities, localization, allowEmptyOption = false) {
  const arr = allowEmptyOption ? [{ value: '', label: localization.noPostalCodeSelected }] : [];

  cities?.map((e) => {
    return arr.push({ value: e.postal_code, label: e.postal_code, cityId: e.id });
  });

  return arr;
}

export function filterIncotermsToOptions(incoterms, localization) {
  const options = parseDbEnumsForSelect(incoterms, localization);

  return options || [];
}

export function filterCmrsToOptions(cmr, localization, prefix) {
  const options = parseCmrValuesForSelect(cmr, localization, prefix);

  return options || [];
}

// created to add a '.' after every 3 numbers, example: 10.000, 100.000, 1.000.000
export const numberWithCommas = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

/**
 *
 * @param obj
 * @returns true if object is empty or if obj is falsy
 */
export function isEmptyObject(obj) {
  if (!obj) return true;

  return Object.keys(obj).length === 0;
}

/**
 * Converts snake case to camel case
 *
 * @param {*} s
 * @returns
 */
export const toCamelCase = (s) => {
  return s.replace(/([-_][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace('-', '').replace('_', '');
  });
};

export function formatPhone(phone) {
  let format = phone.split(' ');
  format.shift();
  let result = format.join(' ');
  return result;
}

/**
 * Generates an array of time options in 30-minute intervals.
 *
 * @returns {Array<Object>} An array of objects, each containing a `value` in "HH:MM:SS" format and a `label` in "HH:MM" format.
 *
 * @example
 * // Returns an array with objects like { value: '00:00:00', label: '00:00' }, { value: '00:30:00', label: '00:30' }, ...
 * timeOptions();
 *
 * The function generates time options from '00:00' to '23:30' in 30-minute intervals.
 */
export const timeOptions = (() => {
  const options = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 30) {
      const hh = String(hour).padStart(2, '0');
      const mm = String(minute).padStart(2, '0');
      options.push({ value: `${hh}:${mm}:00`, label: `${hh}:${mm}` });
    }
  }
  return options;
})();

export const transitOptions = [
  { value: 'MORE_THAN_TEN_DAYS', label: '+10 days' },
  { value: 'NINE_DAYS', label: '9 days' },
  { value: 'EIGHT_DAYS', label: '8 days' },
  { value: 'SEVEN_DAYS', label: '7 days' },
  { value: 'SIX_DAYS', label: '6 days' },
  { value: 'FIVE_DAYS', label: '5 days' },
  { value: 'FOUR_DAYS', label: '4 days' },
  { value: 'THREE_DAYS', label: '3 days' },
  { value: 'TWO_DAYS', label: '2 days' },
  { value: 'ONE_DAY', label: '1 day' },
  { value: 'SAME_DAY', label: 'Same day' }
];

export const countryCodes = [
  { code: 'ad', countryName: 'Andorra' },
  { code: 'al', countryName: 'Albania' },
  { code: 'at', countryName: 'Austria' },
  { code: 'ba', countryName: 'Bosnia and Herzegovina' },
  { code: 'be', countryName: 'Belgium' },
  { code: 'bg', countryName: 'Bulgaria' },
  { code: 'by', countryName: 'Belarus' },
  { code: 'ch', countryName: 'Switzerland' },
  { code: 'cz', countryName: 'Czechia' },
  { code: 'de', countryName: 'Germany' },
  { code: 'dk', countryName: 'Denmark' },
  { code: 'ee', countryName: 'Estonia' },
  { code: 'es', countryName: 'Spain' },
  { code: 'fi', countryName: 'Finland' },
  { code: 'fo', countryName: 'Faroe Islands' },
  { code: 'fr', countryName: 'France' },
  { code: 'gb', countryName: 'United Kingdom of Great Britain' },
  { code: 'ge', countryName: 'Georgia' },
  { code: 'gi', countryName: 'Gibraltar' },
  { code: 'gr', countryName: 'Greece' },
  { code: 'hr', countryName: 'Croatia' },
  { code: 'hu', countryName: 'Hungary' },
  { code: 'ie', countryName: 'Ireland' },
  { code: 'im', countryName: 'Isle of Man' },
  { code: 'is', countryName: 'Iceland' },
  { code: 'it', countryName: 'Italy' },
  { code: 'li', countryName: 'Liechtenstein' },
  { code: 'lt', countryName: 'Lithuania' },
  { code: 'lu', countryName: 'Luxembourg' },
  { code: 'lv', countryName: 'Latvia' },
  { code: 'mc', countryName: 'Monaco' },
  { code: 'md', countryName: 'Moldova' },
  { code: 'me', countryName: 'Montenegro' },
  { code: 'mk', countryName: 'North Macedonia' },
  { code: 'mt', countryName: 'Malta' },
  { code: 'nl', countryName: 'Netherlands' },
  { code: 'no', countryName: 'Norway' },
  { code: 'pl', countryName: 'Poland' },
  { code: 'pt', countryName: 'Portugal' },
  { code: 'ro', countryName: 'Romania' },
  { code: 'rs', countryName: 'Serbia' },
  { code: 'ru', countryName: 'Russian Federation' },
  { code: 'se', countryName: 'Sweden' },
  { code: 'si', countryName: 'Slovenia' },
  { code: 'sk', countryName: 'Slovakia' },
  { code: 'sm', countryName: 'San Marino' },
  { code: 'tr', countryName: 'Türkiye' },
  { code: 'ua', countryName: 'Ukraine' },
  { code: 'va', countryName: 'Holy See' },
  { code: 'xk', countryName: 'Kosovo' },
  { code: 'az', countryName: 'Azerbaijan' },
  { code: 'am', countryName: 'Armenia' },
  { code: 'bh', countryName: 'Bahrain' },
  { code: 'ge', countryName: 'Georgia' },
  // { code: 'ir', countryName: 'Iran' },
  // { code: 'iq', countryName: 'Iraq' },
  { code: 'il', countryName: 'Israel' },
  // { code: 'jo', countryName: 'Jordan' },
  { code: 'kw', countryName: 'Kuwait' },
  { code: 'lb', countryName: 'Lebanon' },
  { code: 'om', countryName: 'Oman' },
  // { code: 'qa', countryName: 'Qatar' },
  { code: 'sa', countryName: 'Saudi Arabia' },
  // { code: 'sy', countryName: 'Syrian Arab Republic' },
  { code: 'tm', countryName: 'Turkmenistan' },
  // { code: 'ye', countryName: 'Yemen' },
  { code: 'uz', countryName: 'Uzbekistan' },
  { code: 'kg', countryName: 'Kyrgyzstan' },
  { code: 'kz', countryName: 'Kazakhstan' },
  // { code: 'ae', countryName: 'United Arab Emirates' },
  // { code: 'cy', countryName: 'Cyprus' },
  { code: 'eg', countryName: 'Egypt' },
  { code: 'ps', countryName: 'Palestine, State of' },
  { code: 'tj', countryName: 'Tajikistan' }
];

export const sectionType = {
  VEHICLE: 1,
  CARGO: 2,
  WAREHOUSE: 3
};

export const subSectionType = {
  VEHICLE: {
    MY_OFFERS: 1,
    MY_BIDS: 2
  },
  CARGO: {
    MY_OFFERS: 3,
    MY_BIDS: 4
  },
  WAREHOUSE: {
    MY_OFFERS: 5,
    MY_BIDS: 6
  }
};

/**
 *
 * @param {*} type
 * @param {*} params (obj) for example { bid_id: 10 }
 * @returns
 */
export const getRouteHash = (hashTemplate, params) => {
  let hash = hashTemplate;

  for (const [key, value] of Object.entries(params)) {
    hash = hash.replace(`<${key}>`, value);
  }

  return hash;
};

/**
 *
 * @param {*} type
 * @param {*} params (obj) for example { bidId: 10 }
 * @returns
 */
export const getPathWithParams = (path, params) => {
  let pathWithParams = path;

  for (const [key, value] of Object.entries(params)) {
    pathWithParams = pathWithParams.replace(`:${key}`, value);
  }

  return pathWithParams;
};

export const getAgreementId = (id, type) => {
  switch (Number(type)) {
    case agreementType.VEHICLE:
      return `V${id}`;
    case agreementType.CARGO:
      return `C${id}`;
    case agreementType.WAREHOUSE:
      return `W${id}`;
    default:
      break;
  }
};

export const getAgreementName = (id, type, localization) => {
  let name = '';

  switch (type) {
    case agreementType.VEHICLE:
      name = localization.transportAgreement + ' #' + getAgreementId(id, type);
      break;
    case agreementType.CARGO:
      name = localization.transportAgreement + ' #' + getAgreementId(id, type);
      break;
    case agreementType.WAREHOUSE:
      name = localization.storageAgreement + ' #' + getAgreementId(id, type);
      break;

    default:
      break;
  }

  return name;
};

export const handleKeyDown = (event, limit) => {
  if (event.target.value.length >= limit && event.key !== 'Backspace') {
    event.preventDefault();
  }
};

export const getAgreementNestedRoute = (agreementTypeVal, agreementId) => {
  switch (Number(agreementTypeVal)) {
    case agreementType.VEHICLE:
      return getPathWithParams(nestedCompanyRoutes.VIEW_VEHICLE_AGREEMENT, { agreementId });

    case agreementType.CARGO:
      return getPathWithParams(nestedCompanyRoutes.VIEW_CARGO_AGREEMENT, { agreementId });
    case agreementType.WAREHOUSE:
      return getPathWithParams(nestedCompanyRoutes.VIEW_WAREHOUSE_AGREEMENT, { agreementId });
    default:
      break;
  }
};

export const mapIconsToCompanyTypes = {
  1: WarehouseCompTypeSvg,
  2: TransportSvg,
  3: ImportExportSvg,
  4: FreightForwarderSvg,
  5: OtherSvg,
  6: ManufacturingSvg,
  7: TradeSvg,
  8: ImportExportSvg,
  9: FreightForwarderSvg,
  10: CustomsSvg,
  11: ThreePLSvg,
  12: ServiceProviderSvg
};

export const mapIconsToCargoTypes = {
  1: BeveragesSvg,
  2: ChemicalsSvg,
  3: FurnitureSvg,
  4: IndustrialSvg,
  5: ApparelsSvg,
  6: BuildingMaterialsSvg,
  7: VehiclesEnginesSvg,
  8: ApplianceElectronicsSvg,
  9: GlassPaperSvg,
  10: HazardousSvg,
  11: OtherTypesSvg
};

export const getCompanyTypeById = (id) => {
  switch (id) {
    case 1:
      return localization.WAREHOUSE;
    case 2:
      return localization.TRANSPORT;
    case 3:
      return localization.PRODUCTION_TRADE_IMPORT_EXPORT;
    case 4:
      return localization.SPEDITION;
    case 5:
      return localization.OTHER;
    case 6:
      return localization.MANUFACTURING;
    case 7:
      return localization.TRADE;
    case 8:
      return localization.IMPORT_EXPORT;
    case 9:
      return localization.FREIGHT_FORWARDER;
    case 10:
      return localization.CUSTOMS_BROKERS;
    case 11:
      return localization.THIRD_PARTY_LOGISTICS_3PL_4PL_5PL;
    case 12:
      return localization.SERVICE_PROVIDER;
    default:
      return '';
  }
};

/**
 * Returns the corresponding icon and localized text for a given cargo type.
 *
 * @param {number} cargoType - The cargo type identifier.
 * @param {object} localization - The localization object containing text for each cargo type.
 * @returns {object} An object containing the icon component and localized text for the given cargo type.
 *
 * @example
 * // Returns { icon: BeveragesSvg, text: localization.BEVERAGES_FOODS_PLANTS }
 * getCargoTypeInfo(1, localization);
 *
 * @example
 * // Returns { icon: ChemicalsSvg, text: localization.CHEMICALS_AND_HEALTCARE }
 * getCargoTypeInfo(2, localization);
 *
 * The function uses an object lookup to return the appropriate icon component
 * and localized text based on the input cargo type. If the cargo type is not recognized,
 * it returns the default icon and text for 'OTHER'.
 */
export const getCargoTypeInfo = (cargoType, localization) => {
  const cargoTypes = {
    1: { icon: BeveragesSvg, text: localization.BEVERAGES_FOODS_PLANTS },
    2: { icon: ChemicalsSvg, text: localization.CHEMICALS_AND_HEALTCARE },
    3: { icon: FurnitureSvg, text: localization.FURNITURE_AND_ACCESSORIES },
    4: { icon: IndustrialSvg, text: localization.INDUSTRIAL_PRODUCTS },
    5: { icon: ApparelsSvg, text: localization.APPARELS_AND_ACCESSORIES },
    6: { icon: BuildingMaterialsSvg, text: localization.BUILDING_MATERIALS },
    7: { icon: VehiclesEnginesSvg, text: localization.VEHICLES_ENGINES_AND_SPARE_PARTS },
    8: { icon: ApplianceElectronicsSvg, text: localization.APPLIANCES_AND_ELECTRONICS },
    9: { icon: GlassPaperSvg, text: localization.GLASS_PAPER_PACKAGING },
    10: { icon: HazardousSvg, text: localization.HAZARDOUS_ADR },
    11: { icon: OtherTypesSvg, text: localization.OTHER }
  };

  return cargoTypes[cargoType] || { icon: OtherTypesSvg, text: localization.OTHER };
};

/**
 * Returns the corresponding color code for a given status.
 *
 * @param {string} status - The status for which to get the color code. Valid values are 'PENDING', 'LOADING', 'IN TRANSIT', 'AT CUSTOMS', 'UNLOADING', 'COMPLETED', 'CANCELED'.
 * @returns {string} The corresponding color code as a string. Returns a default color of '#000' if the status is not recognized.
 *
 * @example
 * // Returns '#425264'
 * getStatusColor('PENDING');
 *
 * @example
 * // Returns '#ebb70e'
 * getStatusColor('LOADING');
 *
 * @example
 * // Returns '#005b9e'
 * getStatusColor('IN TRANSIT');
 *
 * @example
 * // Returns '#aa51d4'
 * getStatusColor('AT CUSTOMS');
 *
 * @example
 * // Returns '#f07b0f'
 * getStatusColor('UNLOADING');
 *
 * @example
 * // Returns '#579955'
 * getStatusColor('COMPLETED');
 *
 * @example
 * // Returns '#e75951'
 * getStatusColor('CANCELED');
 *
 * The function uses an object lookup to return the appropriate color code
 * based on the input status. If the status is not recognized, it returns a default color of '#000'.
 */
export const getStatusColor = (status) => {
  const statusColors = {
    PENDING: '#425264',
    LOADING: '#ebb70e',
    'IN TRANSIT': '#005b9e',
    'AT CUSTOMS': '#aa51d4',
    UNLOADING: '#f07b0f',
    COMPLETED: '#579955',
    CANCELED: '#e75951'
  };

  return statusColors[status] || '#000'; // Default color
};

/**
 * Returns the appropriate transport icon component based on the transport type.
 *
 * @param {string} type - The type of transport. Valid values are 'ROAD', 'SEA', 'AIR', 'RAIL'.
 * @returns {JSX.Element|null} The corresponding icon component for the given transport type, or null if the type is not recognized.
 *
 * @example
 * // Returns <RoadIcon />
 * getTransportIcon('ROAD');
 *
 * @example
 * // Returns <SeaIcon />
 * getTransportIcon('SEA');
 *
 * @example
 * // Returns <AirIcon />
 * getTransportIcon('AIR');
 *
 * @example
 * // Returns <RailIcon />
 * getTransportIcon('RAIL');
 *
 * The function uses an object lookup to return the appropriate icon component
 * based on the input transport type. If the type is not recognized, it returns null.
 */
export const getTransportIcon = (type) => {
  const transportIcons = {
    ROAD: <RoadIcon />,
    SEA: <SeaIcon />,
    AIR: <AirIcon />,
    RAIL: <RailIcon />,
    COURIER: <CourierIcon />
  };

  return transportIcons[type] || null;
};

/**
 * Formats a date string into a more readable format.
 *
 * @param {string} dateString - The date string to format.
 * @returns {string} A formatted date string in the format "DayOfWeek, Month Day".
 *
 * @example
 * // Returns "Mon, January 1"
 * formatDate('2023-01-01');
 *
 * @example
 * // Returns "Fri, July 4"
 * formatDate('2024-07-04');
 *
 * The function takes a date string, creates a Date object from it, and then
 * formats it to return a string that includes the abbreviated day of the week,
 * the full month name, and the day of the month.
 */
export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const options = { weekday: 'short', month: 'long', day: 'numeric' };
  const [dayOfWeek, month, day] = date.toLocaleDateString('en-US', options).split(' ');

  return `${dayOfWeek} ${month} ${day}`;
};

/**
 * Formats a time string to "HH:MM" format.
 *
 * @param {string} time - The time string to format. Expected format is "HH:MM:SS".
 * @returns {string} A formatted time string in the format "HH:MM". Returns an empty string if the input is falsy.
 *
 * @example
 * // Returns "14:30"
 * formatTime('14:30:00');
 *
 * @example
 * // Returns ""
 * formatTime('');
 *
 * The function takes a time string, splits it by the colon character,
 * and returns the hours and minutes in "HH:MM" format. If the input is falsy,
 * it returns an empty string.
 */
export const formatTime = (time) => {
  if (!time) return '';
  const [hours, minutes] = time.split(':');
  return `${hours}:${minutes}`;
};

// show document type icon based on document extension
const extensionIconMap = {
  '.pdf': PdfIconSvg,
  '.jpg': JpgIconSvg,
  '.png': PngIconSvg,
  '.xls': XlsIconSvg,
  '.xlsx': XlsIconSvg,
  '.docx': DocIconSvg,
  '.doc': DocIconSvg,
  '.odt': OdtIconSvg,
  '.ods': OdsIconSvg
};

export const getDocumentIcon = (extension, className) => {
  if (!extension) {
    // Return a default icon or null if the extension is not valid
    return null;
  }
  const normalizedExtension = extension.startsWith('.') ? extension : `.${extension}`;
  const IconComponent = extensionIconMap[normalizedExtension];
  return IconComponent ? <IconComponent className={className} /> : null;
};
