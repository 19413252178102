/* eslint-disable */

import { parseDbEnums } from '../assets/helpers/common';

export const bulk = 1;
export const liquid = 2;
export const box = 3;
export const pallet = 4;
export const rolls = 5;
export const container = 6;
export const oversized = 7;
export const europallet = 8;

export const BULK = 'BULK';
export const LIQUID = 'LIQUID';
export const BOX = 'BOX';
export const PALLET = 'PALLET';
export const ROLLS = 'ROLLS';
export const CONTAINER = 'CONTAINER';
export const OVERSIZED = 'OVERSIZED';
export const EUROPALLET = 'EUROPALLET';

export const PALLET_120x120 = 1;
export const PALLET_120x100 = 2;
export const PALLET_120x80 = 3;
export const PALLET_100x100 = 4;
export const PALLET_100x80 = 5;

export const parsePackagingMethod = (packagingMethod, localization, additionalData = {}) => {
  const { width, length } = additionalData;

  switch (packagingMethod) {
    case pallet:
    case PALLET:
      return width && length
        ? `${localization[PALLET]} ${(width * 100).toFixed(0)}X${(length * 100).toFixed(0)}`
        : localization[PALLET];
    case bulk:
    case BULK:
      return localization[BULK];
    case box:
    case BOX:
      return localization['PACKAGING_METHOD_BOX'];
    case liquid:
    case LIQUID:
      return localization[LIQUID];
    case rolls:
    case ROLLS:
      return localization[ROLLS];
    case container:
    case CONTAINER:
      return localization[CONTAINER];
    case oversized:
    case OVERSIZED:
      return localization[OVERSIZED];
    case EUROPALLET:
    case europallet:
      return width && length
        ? `${localization[EUROPALLET]} ${(width * 100).toFixed(0)}X${(length * 100).toFixed(0)}`
        : localization[EUROPALLET];

    default:
      return '';
  }
};
